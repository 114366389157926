import React, { useEffect, useState } from "react";
import NameDesc from "../components/addProduct/NameDesc";
import PriceCateStoch from "../components/addProduct/PriceCateStoch";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import AddImages from "../components/addProduct/AddImages";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import { axiosInstance } from "../config/axiosInstance";
import swal from "sweetalert";
import axios from "axios";
import loader from "../assets/001 EN.gif";
import { useLocation, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import AdditionalEdit from "../components/addProduct/AdditionalEdit";

function EditProduct() {
  const nav = useNavigate();
  const url = useLocation();
  const prodid = url.pathname.split("/");
  const steps = [
    "Title and Description",
    "Category, Price and Quantity",
    "Images",
    "Additional Properties",
  ];
  const [theme, setTheme] = useState("");
  const [fullData, setFullData] = useState({
    added_by: "admin",
    properties: [],
    gallery: [],
    price: 1,
    quantity: 1,
    minimum_quantity: 1,
    _method: "PATCH",
  });
  const [stage, setStage] = useState(0);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorDesc, setErrorDesc] = useState("");
  const [errorCate, setErrorCate] = useState("");
  const [errorType, setErrorType] = useState("");
  const [errorPrice, setErrorPrice] = useState("");
  const [errorQty, setErrorQty] = useState("");
  const [errorTitleAr, setErrorTitleAr] = useState("");
  const [errorDescAr, setErrorDescAr] = useState("");
  const [errorLimit, setErrorLimit] = useState("");
  const [errorLimitP, setErrorLimitP] = useState("");
  const [errorDisplayOrder, setErrorDisplayOrder] = useState("");
  const [request, setRequest] = useState(false);
  const [errorProps, setErrorProps] = useState("");
  const [categories, setCategories] = useState(null);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState(null)
  const [titleAr, setTitleAr] = useState(null)
  const [description, setDescription] = useState(null)
  const [descriptionAr, setDescriptionAr] = useState(null)
  const [cate, setCate] = useState(null)
  const [type, setType] = useState(null)
  const [limitP, setLimitP] = useState(null);
  const [price, setPrice] = useState(null);
  const [qty, setQty] = useState(null)
  const [limit, setLimit] = useState(null);
  const [displayOrder, setDisplayOrder] = useState(null);
  const [cover, setCover] = useState(null)
  const [gallery, setGallery] = useState(null)
  const [props, setProps] = useState(null)
  const daymode = useSelector((state) => {
    return state.switchMode.mode;
  });
  useEffect(() => {
    const thememode = localStorage.getItem("theme");
    setTheme(thememode);
  }, [daymode]);
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios
      .get("https://api.2ladue.com/sanctum/csrf-cookie")
      .then((response) => {
        console.log(response);
        axiosInstance
          .get("modules/product-category")
          .then((res) => {
            console.log(res.data.data);
            setCategories(res.data.data.data);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
            swal("Something went wrong");
          });
      })
      .catch((err) => {
        console.log(err);
        swal("Something went wrong");
      });
  }, []);
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios
      .get("https://api.2ladue.com/sanctum/csrf-cookie")
      .then((response) => {
        console.log(response);
        axiosInstance
          .get(`/modules/product/${prodid[prodid.length - 1]}`)
          .then((res) => {
            console.log(res.data.data);
            // setProduct(res.data.data);
            setTitle(res.data.data?.title_en)
            setTitleAr(res.data.data?.title_ar)
            setDescriptionAr(res.data.data?.description_ar)
            setDescription(res.data.data?.description_en)
            setCate(res.data.data?.category?.id)
            setType(res.data.data?.type)
            setLimitP(res.data.data?.direct_purchase_limit_period)
            setPrice(res.data.data?.price)
            setQty(res.data.data?.main_quantity)
            setLimit(res.data.data?.direct_purchase_limit)
            setDisplayOrder(res.data.data?.display_order)
            setCover(res.data.data?.cover)
            setGallery(res.data.data?.image)
            setProps(res.data.data?.properties)
            setFullData({
              ...fullData,
              title: {
                ar: res.data.data?.title_ar,
                en: res.data.data?.title_en,
              },
              description: {
                ar: res.data.data?.description_ar,
                en: res.data.data?.description_en,
              },
              type: res.data.data?.type,
              product_category_id: res.data.data?.category?.id,
              direct_purchase_limit_period: res.data.data?.direct_purchase_limit_period,
              price: res.data.data?.price,
              quantity: res.data.data?.main_quantity,
              current_quantity: res.data.data?.main_quantity,
              main_quantity: res.data.data?.main_quantity,
              display_order: res.data.data?.display_order,
              direct_purchase_limit: res.data.data?.direct_purchase_limit,
              // gallery: res.data.data?.image,
              // cover: res.data.data?.cover,
              properties: res.data.data?.properties,
            })
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [open, setOpen] = React.useState(false);
  const [openErr, setOpenErr] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenErr = () => {
    setOpenErr(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseErr = () => {
    setOpenErr(false);
  };
  function handleDisable() {
    if (stage === 0) {
      if (
        (fullData?.title?.en &&
          fullData?.title?.ar &&
          fullData?.description?.en &&
          fullData?.description?.ar) ||
        request
      )
        return false;
      else return true;
    } else if (stage === 1) {
      if (
        (fullData?.type &&
          fullData?.product_category_id &&
          fullData?.direct_purchase_limit_period &&
          fullData?.price &&
          fullData?.quantity &&
          fullData?.minimum_quantity &&
          fullData?.current_quantity &&
          fullData?.main_quantity &&
          fullData?.display_order &&
          fullData?.direct_purchase_limit) ||
        request
      )
        return false;
      else return true;
    } else if (stage === 2) {
      // if (request)
        return false;
      // else return true;
    } else if (stage === 3) {
      if (errorProps === "" || request) return false;
      else return true;
    }
  }
  const handleAddProduct = () => {
    setRequest(true);
    const form = new FormData();
    for (let dataKey in fullData) {
      if (dataKey === "title") {
        for (let previewKey in fullData[dataKey]) {
          form.append(`title[${previewKey}]`, fullData[dataKey][previewKey]);
        }
      } else if (dataKey === "description") {
        for (let previewKey in fullData[dataKey]) {
          form.append(
            `description[${previewKey}]`,
            fullData[dataKey][previewKey]
          );
        }
      } else if (dataKey === "properties") {
        for (let previewKey in fullData[dataKey]) {
          form.append(
            `properties[${previewKey}]`,
            fullData[dataKey][previewKey]
          );
        }
      } else {
        form.append(dataKey, fullData[dataKey]);
      }
    }
    form.append("_method", "PATCH");
    axios
      .get("https://api.2ladue.com/sanctum/csrf-cookie")
      .then((result) => {
        console.log(result);
        axiosInstance
          .post(`/modules/product/${prodid[prodid.length - 1]}`, fullData)
          .then((res) => {
            console.log(res);
            handleClose();
            swal("Product edited successfully");
            setRequest(false);
            nav("/products");
          })
          .catch((err) => {
            console.log(err);
            swal("Something went wrong");
            setRequest(false);
          });
      })
      .catch((err) => {
        console.log(err);
        swal("Something went wrong");
        setRequest(false);
      });
  };

  return (
    <>
      {loading ? (
        <div
          className=" d-flex justify-content-center"
          style={{ height: "100vh", backgroundColor: "#fcfcfb" }}
        >
          <img alt="loading" src={loader} className="loading-img" />
        </div>
      ) : (
        <div
          style={{ width: "100%", minHeight: "100vh" }}
          className={`${theme === "Night" ? "bg-dark" : "bg-light"} py-5`}
        >
          <div className=" container px-5">
            <Stepper activeStep={stage} alternativeLabel sx={{ mb: 10 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>
                    <span
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {label}
                    </span>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {stage === 0 && (
              <NameDesc
                setFullData={setFullData}
                stage={stage}
                setStage={setStage}
                title={"Title and Description"}
                fullData={fullData}
                setErrorTitle={setErrorTitle}
                setErrorDesc={setErrorDesc}
                errorTitle={errorTitle}
                errorDesc={errorDesc}
                setErrorTitleAr={setErrorTitleAr}
                errorTitleAr={errorTitleAr}
                errorDescAr={errorDescAr}
                setErrorDescAr={setErrorDescAr}
                titl={title}
                titleAr={titleAr}
                setTitle={setTitle}
                setTitleAr={setTitleAr}
                setDescription={setDescription}
                setDescriptionAr={setDescriptionAr}
                description={description}
                descriptionAr={descriptionAr}
              />
            )}
            {stage === 1 && (
              <PriceCateStoch
                setFullData={setFullData}
                stage={stage}
                setStage={setStage}
                title={"Category, Price and Quantity"}
                fullData={fullData}
                setErrorCate={setErrorCate}
                errorCate={errorCate}
                setErrorPrice={setErrorPrice}
                errorPrice={errorPrice}
                errorQty={errorQty}
                setErrorQty={setErrorQty}
                setErrorType={setErrorType}
                errorType={errorType}
                setErrorLimitP={setErrorLimitP}
                setErrorLimit={setErrorLimit}
                errorLimit={errorLimit}
                errorLimitP={errorLimitP}
                setErrorDisplayOrder={setErrorDisplayOrder}
                errorDisplayOrder={errorDisplayOrder}
                categories={categories}
                cate={cate}
                setCate={setCate}
                type={type}
                setType={setType}
                limitP={limitP}
                setLimitP={setLimitP}
                limitVal={limit}
                setLimitVal={setLimit}
                price={price}
                setPrice={setPrice}
                qty={qty}
                setQty={setQty}
                displayOrderVal={displayOrder}
                setDisplayOrderVal={setDisplayOrder}
              />
            )}
            {stage === 2 && (
              <AddImages
                setFullData={setFullData}
                stage={stage}
                setStage={setStage}
                title={"Images"}
                cover={"Cover Image"}
                fullData={fullData}
                gallery={gallery}
                coverr={cover}
                setCover={setCover}
                setGallery={setGallery}
              />
            )}
            {stage === 3 && (
              <AdditionalEdit
                title={"Additional Properties"}
                setFullData={setFullData}
                fullData={fullData}
                setErrorProps={setErrorProps}
                props={props}
                setProps={setProps}
              />
            )}
            <div className=" d-flex justify-content-between">
              <Button
                disabled={(stage === 0)}
                variant="contained"
                sx={{ width: 120 }}
                onClick={() => {
                  if (stage > 0) {
                    setStage(stage - 1);
                  } else {
                    setStage(0);
                  }
                }}
              >
                <div className=" d-flex justify-content-between w-100 align-items-center">
                  <ArrowBackIosNewIcon style={{ fontSize: 15 }} />
                  <span>Back</span>
                </div>
              </Button>
              <Button
                variant="contained"
                disabled={handleDisable()}
                sx={{ width: 120 }}
                onClick={() => {
                  if (stage < 3) {
                    setStage(stage + 1);
                  } else {
                    console.log(`1: ${errorCate}, 2: ${errorDesc}, 4: ${errorDescAr}, 5: ${errorPrice}, 6: ${errorQty},
                  7: ${errorTitle}, 9: ${errorTitleAr}, 10: ${errorType}, 11: ${errorLimitP}, 12: ${errorLimit}
                `);
                    if (
                      errorCate === "" &&
                      errorDesc === "" &&
                      errorDescAr === "" &&
                      errorPrice === "" &&
                      errorQty === "" &&
                      errorTitle === "" &&
                      errorTitleAr === "" &&
                      errorType === "" &&
                      errorLimitP === "" &&
                      errorLimit === "" &&
                      errorDisplayOrder === ""
                    ) {
                      handleClickOpen();
                    } else {
                      handleClickOpenErr();
                    }
                  }
                }}
              >
                <div className=" d-flex justify-content-between w-100 align-items-center">
                  <span>{stage === 3 ? "Confirm" : "Next"}</span>
                  <ArrowForwardIosIcon style={{ fontSize: 15 }} />
                </div>
              </Button>
            </div>
          </div>
          <Dialog
            open={open}
            // TransitionComponent={Transition}
            keepMounted
            fullWidth
            maxWidth="sm"
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>
              <p>{"Edit Product"}</p>
              <Divider sx={{ borderColor: "black" }} />
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure you want to edit product with previous data?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                variant="contained"
                onClick={handleAddProduct}
                disabled={request}
              >
                {request ? (
                  <CircularProgress size={14} sx={{ color: "white" }} />
                ) : (
                  "Edit"
                )}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openErr}
            // TransitionComponent={Transition}
            keepMounted
            fullWidth
            maxWidth="sm"
            onClose={handleCloseErr}
            aria-describedby="alert-dialog-slide-description1"
          >
            <DialogTitle>
              <p>{"Missing Data"}</p>
              <Divider sx={{ borderColor: "black" }} />
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description1">
                You have left empty fields
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseErr}>Back</Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
}

export default EditProduct;
