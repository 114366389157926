import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import { axiosInstance } from "../config/axiosInstance";
import swal from "sweetalert";
import TitleDesc from "../components/addPackage/TitleDesc";
import BidDuration from "../components/addPackage/BidDuration";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import loader from "../assets/001 EN.gif";

function EditPackage() {
  const url = useLocation();
  const nav = useNavigate();
  const prodid = url.pathname.split("/");
  const steps = ["Title and Description", "Bid Value, Type and Duration"];
  const [theme, setTheme] = useState("");
  const [stage, setStage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorDesc, setErrorDesc] = useState("");
  const [errorTitleAr, setErrorTitleAr] = useState("");
  const [errorDescAr, setErrorDescAr] = useState("");
  const [errorBid, setErrorBid] = useState("");
  const [errorType, setErrorType] = useState("");
  const [errorTotal, setErrorTotal] = useState("");
  const [errorDuration, setErrorDuration] = useState("");
  const [errorDurationType, setErrorDurationType] = useState("");
  const [open, setOpen] = React.useState(false);
  const [openErr, setOpenErr] = React.useState(false);
  const [title, setTitle] = useState("");
  const [titleAr, setTitleAr] = useState("");
  const [desc, setDesc] = useState("");
  const [pack, setPack] = useState(null);
  const [request, setRequest] = useState(false);
  const [descAr, setDescAr] = useState("");
  const [dur, setDur] = useState(null)
  const [fullData, setFullData] = useState({
    total_bids: 1,
    duration: 1,
    bid_value: 1,
  });
  const daymode = useSelector((state) => {
    return state.switchMode.mode;
  });
  useEffect(() => {
    const thememode = localStorage.getItem("theme");
    setTheme(thememode);
  }, [daymode]);
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios
      .get("https://api.2ladue.com/sanctum/csrf-cookie")
      .then((response) => {
        console.log(response);
        axiosInstance
          .get(`/modules/package/${prodid[prodid.length - 1]}`)
          .then((res) => {
            console.log(res.data.data);
            setTitle(res.data.data?.title_en);
            setTitleAr(res.data.data?.title_ar);
            setDesc(res.data.data?.details_en);
            setDescAr(res.data.data?.details_ar);
            setPack(res.data.data);
            let durr = res.data.data?.duration_str.split(" ")
            console.log(durr);
            setDur(durr[1])
            setFullData({
              ...fullData,
              title: {
                ar: res.data.data?.title_ar,
                en: res.data.data?.title_en,
              },
              details: {
                ar: res.data.data?.details_ar,
                en: res.data.data?.details_en,
              },
              total_bids: res.data.data?.total_bids,
              duration: res.data.data?.duration,
              bid_value: res.data.data?.bid_value,
              duration_type: durr[1]
            });
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
            swal("Something went wrong");
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenErr = () => {
    setOpenErr(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseErr = () => {
    setOpenErr(false);
  };

  const handleAddProduct = () => {
    setRequest(true);
    const form = new FormData();
    for (let dataKey in fullData) {
      if (dataKey === "title") {
        for (let previewKey in fullData[dataKey]) {
          form.append(`title[${previewKey}]`, fullData[dataKey][previewKey]);
        }
      } else if (dataKey === "details") {
        for (let previewKey in fullData[dataKey]) {
          form.append(`details[${previewKey}]`, fullData[dataKey][previewKey]);
        }
      } else {
        form.append(dataKey, fullData[dataKey]);
      }
    }
    form.append("_method", "PATCH");
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios
      .get("https://api.2ladue.com/sanctum/csrf-cookie")
      .then((response) => {
        console.log(response);
        axiosInstance
          .post(`/modules/package/${prodid[prodid.length - 1]}`, form)
          .then((res) => {
            console.log(res);
            handleClose();
            swal("Package edited successfully");
            setRequest(false);
            nav("/packages");
          })
          .catch((err) => {
            console.log(err);
            swal("Something went wrong");
            setRequest(false);
          });
      })
      .catch((err) => {
        console.log(err);
        swal("Something went wrong");
        setRequest(false);
      });
  };
  function handleDisable() {
    if (stage === 0) {
      if (
        (fullData?.title?.ar &&
          fullData?.title?.en &&
          fullData?.details?.en &&
          fullData?.details?.ar) ||
        request
      )
        return false;
      else return true;
    } else if (stage === 1) {
      if (
        (fullData?.bid_value &&
          fullData?.duration &&
          fullData?.total_bids &&
          fullData?.duration_type &&
          fullData?.type) ||
        request
      )
        return false;
      else return true;
    }
  }
  return (
    <>
      {loading ? (
        <div
          className=" d-flex justify-content-center"
          style={{ height: "100vh", backgroundColor: "#fcfcfb" }}
        >
          <img alt="loading" src={loader} className="loading-img" />
        </div>
      ) : (
        <div
          style={{ width: "100%", minHeight: "100vh" }}
          className={`${theme === "Night" ? "bg-dark" : "bg-light"} py-5`}
        >
          <div className=" container px-5">
            <Stepper activeStep={stage} alternativeLabel sx={{ mb: 10 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>
                    <span
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {label}
                    </span>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {stage === 0 && (
              <TitleDesc
                title={"Title and Description"}
                fullData={fullData}
                setFullData={setFullData}
                errorDesc={errorDesc}
                errorTitle={errorTitle}
                errorDescAr={errorDescAr}
                errorTitleAr={errorTitleAr}
                setErrorDesc={setErrorDesc}
                setErrorDescAr={setErrorDescAr}
                setErrorTitle={setErrorTitle}
                setErrorTitleAr={setErrorTitleAr}
                titl={title}
                titleAr={titleAr}
                desc={desc}
                descAr={descAr}
                setTitle={setTitle}
                setTitleAr={setTitleAr}
                setDescAr={setDescAr}
                setDesc={setDesc}
              />
            )}
            {stage === 1 && (
              <BidDuration
                fullData={fullData}
                setFullData={setFullData}
                errorBid={errorBid}
                errorType={errorType}
                errorTotal={errorTotal}
                errorDuration={errorDuration}
                errorDurationType={errorDurationType}
                setErrorBid={setErrorBid}
                setErrorType={setErrorType}
                setErrorTotal={setErrorTotal}
                setErrorDuration={setErrorDuration}
                setErrorDurationType={setErrorDurationType}
                title={"Bid Value, Type and Duration"}
                pack={pack}
                dur={dur}
              />
            )}
            <div className=" d-flex justify-content-between">
              <Button
                disabled={(stage === 0)}
                variant="contained"
                sx={{ width: 120 }}
                onClick={() => {
                  if (stage > 0) {
                    setStage(stage - 1);
                  } else {
                    setStage(0);
                  }
                }}
              >
                <div className=" d-flex justify-content-between w-100 align-items-center">
                  <ArrowBackIosNewIcon style={{ fontSize: 15 }} />
                  <span>Back</span>
                </div>
              </Button>
              <Button
                disabled={handleDisable()}
                variant="contained"
                sx={{ width: 120 }}
                onClick={() => {
                  if (stage < 1) {
                    setStage(stage + 1);
                  } else {
                    if (
                      errorDesc === "" &&
                      errorDescAr === "" &&
                      errorTitleAr === "" &&
                      errorTitle === "" &&
                      errorDuration === "" &&
                      errorBid === "" &&
                      errorTotal === "" &&
                      errorType === "" &&
                      errorDurationType === ""
                    ) {
                      handleClickOpen();
                    } else {
                      handleClickOpenErr();
                    }
                  }
                }}
              >
                <div className=" d-flex justify-content-between w-100 align-items-center">
                  <span>{stage === 1 ? "Confirm" : "Next"}</span>
                  <ArrowForwardIosIcon style={{ fontSize: 15 }} />
                </div>
              </Button>
            </div>
            <Dialog
              open={open}
              // TransitionComponent={Transition}
              keepMounted
              fullWidth
              maxWidth="sm"
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>
                <p>{"Edit Package"}</p>
                <Divider sx={{ borderColor: "black" }} />
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Are you sure you want to edit package with previous data?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button variant="contained" onClick={handleAddProduct} disabled={request}>
                  {request ? (
                    <CircularProgress size={14} sx={{ color: "white" }} />
                  ) : (
                    "Edit"
                  )}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openErr}
              // TransitionComponent={Transition}
              keepMounted
              fullWidth
              maxWidth="sm"
              onClose={handleCloseErr}
              aria-describedby="alert-dialog-slide-description1"
            >
              <DialogTitle>
                <p>{"Missing Data"}</p>
                <Divider sx={{ borderColor: "black" }} />
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description1">
                  You have left empty fields
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseErr}>Back</Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      )}
    </>
  );
}

export default EditPackage;
