import React, { useState, useEffect } from "react";
import logo from "../assets/New Colors_2.gif";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@mui/icons-material/Visibility";
import FormControl from '@mui/material/FormControl';
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import { Link } from "react-router-dom";
import { axiosInstance } from "../config/axiosInstance";
import swal from "sweetalert";
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import '../styles/responsive.css';
import { Cookie } from "@mui/icons-material";

function Login() {
  const [request, setRequest] = useState(false)
  const [theme, setTheme] = useState("");
  const daymode = useSelector((state) => {
    return state.switchMode.mode;
  });
  useEffect(() => {
    const thememode = localStorage.getItem("theme");
    setTheme(thememode);
  }, [daymode]);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const navigate = useNavigate();
  const loginFormik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object().shape({
      email: yup.string().email("Invalid email format").required("Enter your email"),
      password: yup.string().required("Enter your password").min(6, "Password length is less than 6"),
    }),
    onSubmit: (values) => {
      console.log(values);
      setRequest(true)
      const form = new FormData();
      form.append("email", values.email);
      form.append("password", values.password);
      axios.get('https://api.2ladue.com/sanctum/csrf-cookie').then((result)=>{
        console.log(result);
        axiosInstance.post('/auth/login',form).then((res)=>{
          console.log(res.data)
          localStorage.setItem("adm2ladue",JSON.stringify(res.data.data?.user))
          setRequest(false)
          navigate("/dashboard")
        }).catch((err)=>{
          console.log(err)
          swal("Something went wrong")
          setRequest(false)
        })
      })
    },
  });
  return (
    <div
      className={`${theme === "Night" ? "bg-dark" : "bg-light"} py-5`}
      style={{ width: "100%", height: "100vh" }}
    >
      <div
        className="login-screen d-flex justify-content-between rounded-4 p-4"
        // style={{ marginLeft: "150px", marginRight: "150px", backgroundColor: "#fcfcfb" }}
      >
        <div className="login-image w-100 text-center py-4">
          <img alt="logo" src={logo} width={350} height={600} />
        </div>
        <div className=" w-100 text-center d-flex justify-content-center flex-column rounded-3">
          <div className="fs-4 fw-semibold mb-5">Sign In</div>
          <form
            onSubmit={loginFormik.handleSubmit}
            encType="multipart/form-data"
            className="login-form"
          >
            <div className="mb-4">
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                name="email"
                error={loginFormik?.errors?.email}
                fullWidth
                helperText={loginFormik?.errors?.email}
                onChange={loginFormik.handleChange}
                onBlur={loginFormik.handleBlur}
                value={loginFormik.values.email}
              />
            </div>
            <div className="position-relative mb-2">
            <FormControl sx={{ width: '100%' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                fullWidth
                name="password"
                type={showPassword ? 'text' : 'password'}
                error={loginFormik?.errors?.password}
                helperText={loginFormik?.errors?.password}
                onChange={loginFormik.handleChange}
                onBlur={loginFormik.handleBlur}
                value={loginFormik.values.password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                      <Visibility />
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              {loginFormik.errors.password && <small className="text-danger text-start ms-3 my-1" style={{fontSize: 10}}>{loginFormik.errors.password}</small>}
            </FormControl>
            </div>
            <div className="mb-5 d-flex justify-content-end">
              {/* <small>
                <Link to={'/register'}>Register</Link>
              </small> */}
                <small>
                  <Link to={'/forgotpassword'}>Forget your password</Link>
                </small>
            </div>
            <button className="btn btn-primary w-100" type="submit" disabled={loginFormik.errors.email || loginFormik.errors.password || request}
            // onClick={()=>navigate('/home')}
            >
                {request ? <CircularProgress size={14} sx={{color: "white"}} /> : "Login"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;