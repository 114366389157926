import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import prof from "../assets/PI.png";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { axiosInstance } from "../config/axiosInstance";
import swal from "sweetalert";
import axios from "axios";
import { useFormik } from "formik";
import * as yup from "yup";

function Profile({ data, image, edit, setReload, reload }) {
  const [theme, setTheme] = useState("");
  const [profile, setProfile] = useState(null);
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const [index, setIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState(false);
  const daymode = useSelector((state) => {
    return state.switchMode.mode;
  });
  const icons = [
    <Person2OutlinedIcon fontSize="large" />,
    <MailOutlinedIcon fontSize="large" />,
    <LocalPhoneOutlinedIcon fontSize="large" />,
    <LocationOnIcon fontSize="large" />,
  ];
  const EditStyles = styled("div")(({ theme }) => ({
    cursor: "pointer",
    backgroundColor: "#ffe000",
    "&:hover": {
      transform: "scale(1.2)",
    },
  }));
  useEffect(() => {
    setProfile(data);
    setKey("Name");
    setValue(data.name);
  }, []);
  useEffect(() => {
    const thememode = localStorage.getItem("theme");
    setTheme(thememode);
  }, [daymode]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  function showInfo(idx) {
    setIndex(idx);
    switch (idx) {
      case 0:
        setKey("Name");
        if (profile.name) setValue(profile.name);
        else setValue("-");
        break;
      case 1:
        setKey("Email");
        if (profile.email) setValue(profile.email);
        else setValue("-");
        break;
      case 2:
        setKey("Phone");
        if (profile.phone) setValue(profile.phone);
        else setValue("-");
        break;
      case 3:
        setKey("Address");
        if (profile.address) setValue(profile.address.address);
        else setValue("-");
        break;
      default:
        return;
    }
  }
  const editFormik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    validationSchema: yup.object().shape({
      name: yup
        .string()
        .required("Enter your name")
        .min(2, "Name length is less than 2"),
      email: yup
        .string()
        .email("Invalid email format")
        .required("Enter your email"),
    }),
    onSubmit: (values) => {
      console.log(values);
      setRequest(true);
      const form = new FormData();
      form.append("name", values.name);
      form.append("email", values.email);
      axios
        .get("https://api.2ladue.com/sanctum/csrf-cookie")
        .then((result) => {
          console.log(result);
          axiosInstance
            .post("/auth/editprofile", form)
            .then((res) => {
              console.log(res.data.data?.user);
              localStorage.setItem(
                "adm2ladue",
                JSON.stringify(res.data.data?.user)
              );
              setRequest(false);
              handleClose();
              setReload(!reload);
              // navigate("/home")
            })
            .catch((err) => {
              console.log(err);
              swal("Something went wrong");
              setRequest(false);
            });
        })
    },
  });
  return (
    <div
      className="card-style"
      style={{
        boxShadow: "1px 1px 10px 0px lightgrey",
        // backgroundColor: theme === "Night" ? "#202442" : "white",
        height: "150px",
      }}
    >
      <div
        className=" text-center border-bottom"
        style={{ height: "150px", padding: "10px" }}
      >
        {edit && (
          <EditStyles
            className="p-2 rounded-2"
            style={{ width: "50px" }}
            onClick={handleClickOpen}
          >
            <EditOutlinedIcon style={{ color: "white" }} />
          </EditStyles>
        )}
        <img
          alt="prof"
          src={image ? image : prof}
          width={150}
          height={150}
          style={{
            borderRadius: "10rem",
            marginTop: edit ? "8px" : "50px",
          }}
        />
      </div>
      <div
        className=" text-center "
        style={{
          paddingTop: "70px",
          paddingBottom: "70px",
          backgroundColor: theme === "Night" ? "#202442" : "white",
          boxShadow: "1px 1px 10px 0px lightgrey",
        }}
      >
        <div className=" text-center mb-5" style={{ height: "50px" }}>
          <div className="text-secondary fw-semibold pb-2">{key}</div>
          <div
            className="fs-4"
            style={{ color: theme === "Night" ? "white" : "black" }}
          >
            {value}
          </div>
        </div>
        <div className="card-tabs-h">
          <div className="d-flex justify-content-center gap-5">
            <Tabs>
              {icons?.map((item, idx) => {
                return (
                  <Tab
                    onClick={() => showInfo(idx)}
                    key={idx}
                    label={
                      <div
                        key={idx}
                        className="px-4"
                        style={{ color: index === idx ? "#2e8bf2" : "#c4c6cb" }}
                      >
                        <article>{item}</article>
                      </div>
                    }
                  />
                );
              })}
            </Tabs>
          </div>
        </div>
        <div className="card-tabs-v">
          <div className="d-flex justify-content-center gap-5">
            <Tabs orientation="vertical">
              {icons?.map((item, idx) => {
                return (
                  <Tab
                    onClick={() => showInfo(idx)}
                    key={idx}
                    label={
                      <div
                        key={idx}
                        className="px-4"
                        style={{ color: index === idx ? "#2e8bf2" : "#c4c6cb" }}
                      >
                        <article>{item}</article>
                      </div>
                    }
                  />
                );
              })}
            </Tabs>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <p>{"Edit Admin Profile"}</p>
          <Divider sx={{ borderColor: "black" }} />
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to add product with previous data?
          </DialogContentText> */}
          <form
            style={{ padding: "10px", marginTop: "10px" }}
            onSubmit={editFormik.handleSubmit}
            encType="multipart/form-data"
          >
            <div className="mb-4">
              <TextField
                id="outlined-basic"
                label="Name"
                variant="outlined"
                name="name"
                error={editFormik?.errors?.name}
                fullWidth
                helperText={editFormik?.errors?.name}
                onChange={editFormik.handleChange}
                onBlur={editFormik.handleBlur}
                value={editFormik.values.name}
              />
            </div>
            <div className="mb-4">
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                name="email"
                error={editFormik?.errors?.email}
                fullWidth
                helperText={editFormik?.errors?.email}
                onChange={editFormik.handleChange}
                onBlur={editFormik.handleBlur}
                value={editFormik.values.email}
              />
            </div>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                type="submit"
                variant="contained"
                disabled={
                  editFormik.errors.email ||
                  editFormik.errors.password ||
                  request
                }
                // onClick={handleAddProduct}
              >
                {request ? (
                  <CircularProgress size={14} sx={{ color: "white" }} />
                ) : (
                  "Edit"
                )}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Profile;
