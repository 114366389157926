import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { FileUploader } from "react-drag-drop-files";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import { axiosInstance } from "../config/axiosInstance";
import swal from "sweetalert";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

function AddCategory() {
  const nav = useNavigate();
  const fileTypes = ["JPG", "PNG", "JPEG"];
  const [theme, setTheme] = useState("");
  const [imagee, setImagee] = useState(null);
  const [errorTitle, setErrorTitle] = useState("Enter value");
  const [errorTitleAr, setErrorTitleAr] = useState("Enter value");
  const [errorDesc, setErrorDesc] = useState("Enter value");
  const [errorDescAr, setErrorDescAr] = useState("Enter value");
  const [fullData, setFullData] = useState({});
  const [request, setRequest] = useState(false);
  const [openErr, setOpenErr] = React.useState(false);
  const daymode = useSelector((state) => {
    return state.switchMode.mode;
  });
  useEffect(() => {
    const thememode = localStorage.getItem("theme");
    setTheme(thememode);
  }, [daymode]);
  const handleChange = (files) => {
    if (files !== undefined) {
      const fileReader = new FileReader();
      let image = document.createElement("img");
      image.src = URL.createObjectURL(files);
      setImagee(image.src);
      setFullData({
        ...fullData,
        icon: files,
      });
      fileReader.readAsDataURL(files);
    }
  };
  const handleCloseErr = () => {
    setOpenErr(false);
  };
  function handleDisable() {
    if (
      (fullData?.title?.ar &&
        fullData?.title?.en &&
        fullData?.description?.en &&
        fullData?.description?.ar &&
        fullData?.icon) ||
      request
    )
      return false;
    else return true;
  }
  return (
    <>
      <div
        style={{ width: "100%", minHeight: "100vh" }}
        className={`${theme === "Night" ? "bg-dark" : "bg-light"} py-5`}
      >
        <div className=" container px-5">
          <div className="">
            <h2 className="mb-5 fw-semibold" style={{ color: "grey" }}>
              Add Category
            </h2>
          </div>
          <Grid container spacing={4}>
            <Grid item sm={6} xs={12}>
              <InputLabel
                style={{
                  textAlign: "start",
                  marginBottom: 14,
                  fontSize: 20,
                  color: "grey",
                }}
              >
                English Title
              </InputLabel>
              <TextField
                label="Add Title"
                variant="outlined"
                fullWidth
                sx={{ backgroundColor: "white" }}
                error={errorTitle}
                // helperText={errorTitle}
                onChange={(e) => {
                  console.log(fullData);
                  if (e.target.value === "") {
                    setErrorTitle("Enter title");
                  } else if (e.target.value.length < 2) {
                    setErrorTitle("Title length must be at least 2");
                  } else {
                    setErrorTitle("");
                    setFullData({
                      ...fullData,
                      title: {
                        ...fullData.title,
                        en: e.target.value,
                      },
                    });
                  }
                }}
              />
              {errorTitle && (
                <div
                  className="text-danger text-start ms-3 my-1"
                  style={{ fontSize: "12px" }}
                >
                  {errorTitle}
                </div>
              )}
            </Grid>
            <Grid item sm={6} xs={12}>
              <InputLabel
                style={{
                  textAlign: "start",
                  marginBottom: 14,
                  fontSize: 20,
                  color: "grey",
                }}
              >
                Arabic Title
              </InputLabel>
              <TextField
                label="Add Title"
                variant="outlined"
                fullWidth
                sx={{ backgroundColor: "white" }}
                error={errorTitleAr}
                // helperText={errorTitleAr}
                onChange={(e) => {
                  console.log(fullData);
                  if (e.target.value === "") {
                    setErrorTitleAr("Enter title");
                  } else if (e.target.value.length < 2) {
                    setErrorTitleAr("Title length must be at least 2");
                  } else {
                    setErrorTitleAr("");
                    setFullData({
                      ...fullData,
                      title: {
                        ...fullData.title,
                        ar: e.target.value,
                      },
                    });
                  }
                }}
              />
              {errorTitleAr && (
                <div
                  className="text-danger text-start ms-3 my-1"
                  style={{ fontSize: "12px" }}
                >
                  {errorTitleAr}
                </div>
              )}
            </Grid>
            <Grid item sm={6} xs={12}>
              <InputLabel
                style={{
                  textAlign: "start",
                  marginBottom: 14,
                  fontSize: 20,
                  color: "grey",
                }}
              >
                English Description
              </InputLabel>
              <textarea
                className="form-control"
                label="Description"
                sx={{ backgroundColor: "white" }}
                style={{ width: "100%", height: 50 }}
                placeholder="Add Description"
                onChange={(e) => {
                  console.log(fullData);
                  if (e.target.value === "") setErrorDesc("Enter Description");
                  else if (e.target.value.length < 3) {
                    setErrorDesc("Description length must be at least 3");
                  } else {
                    setErrorDesc("");
                    setFullData({
                      ...fullData,
                      description: {
                        ...fullData.description,
                        en: e.target.value,
                      },
                    });
                  }
                }}
              />
              {errorDesc && (
                <div
                  className="text-danger text-start ms-3 my-1"
                  style={{ fontSize: "12px" }}
                >
                  {errorDesc}
                </div>
              )}
            </Grid>
            <Grid item sm={6} xs={12}>
              <InputLabel
                style={{
                  textAlign: "start",
                  marginBottom: 14,
                  fontSize: 20,
                  color: "grey",
                }}
              >
                Arabic Description
              </InputLabel>
              <textarea
                className="form-control"
                label="Description"
                sx={{ backgroundColor: "white" }}
                style={{ width: "100%", height: 50 }}
                placeholder="Add Description"
                onChange={(e) => {
                  console.log(fullData);
                  if (e.target.value === "")
                    setErrorDescAr("Enter Description");
                  else if (e.target.value.length < 3) {
                    setErrorDescAr("Description length must be at least 3");
                  } else {
                    setErrorDescAr("");
                    setFullData({
                      ...fullData,
                      description: {
                        ...fullData.description,
                        ar: e.target.value,
                      },
                    });
                  }
                }}
              />
              {errorDescAr && (
                <div
                  className="text-danger text-start ms-3 my-1"
                  style={{ fontSize: "12px" }}
                >
                  {errorDescAr}
                </div>
              )}
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
              sx={{ display: "flex", flexDirection: "column", gap: 10 }}
            >
              <div className="">
                <div className="" style={{ color: "grey", fontSize: 20 }}>
                  Icon
                </div>
              </div>
              <input
                className="form-control"
                type="file"
                accept="image/*"
                name="file"
                onChange={(e) => handleChange(e.target.files[0])}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              {imagee && (
                <div
                  className=" d-flex justify-content-center mt-5"
                  style={{ height: "150px", overflow: "hidden" }}
                >
                  <img
                    alt={"idd"}
                    src={imagee}
                    width={"auto"}
                    height={"150px"}
                    className=" border border-1 rounded-2"
                  />
                </div>
              )}
            </Grid>
          </Grid>
          <div
            className=" d-flex justify-content-end gap-5"
            style={{ marginTop: 100 }}
          >
            {/* <Button
              variant="outlined"
              style={{ width: 100 }}
              onClick={() => {}}
            >
              <div className=" d-flex justify-content-center w-100 align-items-center">
                <span>Cancel</span>
              </div>
            </Button> */}
            <Button
              disabled={handleDisable() || request}
              variant="contained"
              style={{ width: 100 }}
              onClick={() => {
                setRequest(true);
                if (
                  errorDescAr === "" &&
                  errorTitle === "" &&
                  errorTitleAr === "" &&
                  errorDesc === ""
                ) {
                  const form = new FormData();
                  for (let dataKey in fullData) {
                    if (dataKey === "title") {
                      for (let previewKey in fullData[dataKey]) {
                        form.append(
                          `title[${previewKey}]`,
                          fullData[dataKey][previewKey]
                        );
                      }
                    } else if (dataKey === "description") {
                      for (let previewKey in fullData[dataKey]) {
                        form.append(
                          `description[${previewKey}]`,
                          fullData[dataKey][previewKey]
                        );
                      }
                    } else {
                      form.append(dataKey, fullData[dataKey]);
                    }
                  }
                  axios.defaults.withCredentials = true;
                  axios.defaults.withXSRFToken = true;
                  axios
                    .get("https://api.2ladue.com/sanctum/csrf-cookie")
                    .then((response) => {
                      console.log(response);
                      axiosInstance
                        .post("modules/product-category", form)
                        .then((res) => {
                          console.log(res.data);
                          swal("Category added successfully");
                          setRequest(false);
                          nav("/categories");
                        })
                        .catch((err) => {
                          console.log(err);
                          swal("Something went wrong");
                          setRequest(false);
                        });
                    })
                    .catch((err) => {
                      console.log(err);
                      swal("Something went wrong");
                      setRequest(false);
                    });
                } else {
                  setOpenErr(true);
                }
              }}
            >
              <div className=" d-flex justify-content-center w-100 align-items-center">
                <span>
                  {request ? (
                    <CircularProgress size={14} sx={{ color: "white" }} />
                  ) : (
                    "Add"
                  )}
                </span>
              </div>
            </Button>
          </div>
        </div>
        <Dialog
          open={openErr}
          // TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="sm"
          onClose={handleCloseErr}
          aria-describedby="alert-dialog-slide-description1"
        >
          <DialogTitle>
            <p>{"Missing Data"}</p>
            <Divider sx={{ borderColor: "black" }} />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description1">
              You have left empty fields
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseErr}>Back</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default AddCategory;
