import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Card from "../components/Card";
import loader from "../assets/001 EN.gif";

function Profile() {
  const [theme, setTheme] = useState("");
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const daymode = useSelector((state) => {
    return state.switchMode.mode;
  });
  const adminData = JSON.parse(localStorage.getItem("adm2ladue"));
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [reload]);
  useEffect(() => {
    const thememode = localStorage.getItem("theme");
    setTheme(thememode);
  }, [daymode]);
  return (
    <>
      {loading ? (
        <div
          className=" d-flex justify-content-center"
          style={{ height: "100vh", backgroundColor: "#fcfcfb" }}
        >
          <img alt="loading" src={loader} className="loading-img" />
        </div>
      ) : (
        <div
          className={`${theme === "Night" ? "bg-dark" : "bg-light"} py-5`}
          style={{ width: "100%", minHeight: "100vh" }}
        >
          <div className="container">
            <Card
              data={{
                name: adminData?.name,
                email: adminData?.email,
                phone: adminData?.mobile,
                address: adminData?.address,
              }}
              image={adminData?.image}
              edit={true}
              setReload={setReload}
              reload={reload}
            />
          </div>
          <div
            className="bg-danger"
            style={{ height: "100%", visibility: "hidden" }}
          >
            <p>dfv</p>
            <p>dfv</p>
            <p>dfv</p>
            <p>dfv</p>
            <p>dfv</p>
            <p>dfv</p>
            <p>dfv</p>
            <p>dfv</p>
            <p>dfv</p>
            <p>dfv</p>
            <p>dfv</p>
            <p>dfv</p>
            <p>dfv</p>
          </div>
        </div>
      )}
    </>
  );
}

export default Profile;
