export const switchMode = (state = "Day", action) => {
  if (action.type === "Night") {
    localStorage.setItem("theme", "Night");
    return { ...state, mode: "Night" };
  } else if (action.type === "Day") {
    localStorage.setItem("theme", "Day");
    return { ...state, mode: "Day" };
  } else {
    return state;
  }
};

// export const selectTabReducer = (state = 0, action) => {
//   localStorage.setItem("selected_tab", action.type)
//   return state
// }