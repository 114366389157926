import React, { useState, useEffect } from "react";
import logo from "../assets/New Colors_2.gif";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@mui/icons-material/Visibility";
import FormControl from "@mui/material/FormControl";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import { Link } from "react-router-dom";
import { axiosInstance } from "../config/axiosInstance";
import swal from "sweetalert";

function ResetPassword() {
  const [theme, setTheme] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const daymode = useSelector((state) => {
    return state.switchMode.mode;
  });
  useEffect(() => {
    const thememode = localStorage.getItem("theme");
    setTheme(thememode);
  }, [daymode]);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const navigate = useNavigate();
  const loginFormik = useFormik({
    initialValues: {
      old_password: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: yup.object().shape({
      password: yup
        .string()
        .required("Enter your password")
        .min(9, "Password length is less than 9"),
      password_confirmation: yup
        .string()
        .required("Confirm your password")
        .oneOf([yup.ref("password")], "This doesn't match your password"),
    }),
    onSubmit: (values) => {
      console.log(values);
      const form = new FormData();
      form.append("old_password", values.old_password);
      form.append("password", values.password);
      axiosInstance
        .post("/auth/changpassword", form)
        .then((res) => {
          console.log(res);
          navigate("/profile");
        })
        .catch((err) => {
          console.log(err);
          swal("Something went wrong");
        });
    },
  });
  return (
    <div
      className={`${theme === "Night" ? "bg-dark" : "bg-light"} py-5`}
      style={{ width: "100%", height: "100vh" }}
    >
      <div
        className=" d-flex justify-content-between rounded-4 p-4"
        style={{
          marginLeft: "150px",
          marginRight: "150px",
          backgroundColor: "#fcfcfb",
        }}
      >
        <div className="w-100 text-center py-4">
          <img alt="logo" src={logo} width={350} height={600} />
        </div>
        <div className=" w-100 text-center d-flex justify-content-center flex-column rounded-3">
          <div className="fs-4 fw-semibold mb-5">Reset Password</div>
          <form
            onSubmit={loginFormik.handleSubmit}
            encType="multipart/form-data"
            className=""
            style={{ paddingLeft: "100px", paddingRight: "100px" }}
          >
            <div className="position-relative mb-4">
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Old Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  fullWidth
                  name="password"
                  type={showPassword ? "text" : "password"}
                  onChange={loginFormik.handleChange}
                  onBlur={loginFormik.handleBlur}
                  value={loginFormik.values.old_password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                        <Visibility />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Old Password"
                />
              </FormControl>
            </div>
            <div className="position-relative mb-4">
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password2">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password2"
                  fullWidth
                  name="password"
                  type={showPassword ? "text" : "password"}
                  error={loginFormik?.errors?.password}
                  helperText={loginFormik?.errors?.password}
                  onChange={loginFormik.handleChange}
                  onBlur={loginFormik.handleBlur}
                  value={loginFormik.values.password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                        <Visibility />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                {loginFormik.errors.password && (
                  <small
                    className="text-danger text-start ms-3 my-1"
                    style={{ fontSize: 10 }}
                  >
                    {loginFormik.errors.password}
                  </small>
                )}
              </FormControl>
            </div>
            <div className="position-relative mb-2">
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password1">
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password1"
                  fullWidth
                  name="password_confirmation"
                  type={showPassword ? "text" : "password"}
                  error={loginFormik?.errors?.password_confirmation}
                  helperText={loginFormik?.errors?.password_confirmation}
                  onChange={loginFormik.handleChange}
                  onBlur={loginFormik.handleBlur}
                  value={loginFormik.values.password_confirmation}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                        <Visibility />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Confirm Password"
                />
                {loginFormik.errors.password_confirmation && (
                  <small
                    className="text-danger text-start ms-3 my-1"
                    style={{ fontSize: 10 }}
                  >
                    {loginFormik.errors.password_confirmation}
                  </small>
                )}
              </FormControl>
            </div>
            <div className="mb-5 d-flex justify-content-end">
              <small>
                <Link to={"/"}>Login</Link>
              </small>
              {/* <small>
              <Link to={"/forgotpassword"}>Forget your password</Link>
            </small> */}
            </div>
            <button
              className="btn btn-primary w-100"
              type="submit"
              disabled={
                loginFormik.errors.password ||
                loginFormik.errors.password_confirmation
              }
              //   onClick={() => navigate("/home")}
            >
              Register
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
