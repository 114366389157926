import React from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";

function NameDesc({
  setFullData,
  setStage,
  stage,
  title,
  fullData,
  setErrorDesc,
  setErrorTitle,
  errorDesc,
  errorTitle,
  setErrorTitleAr,
  errorTitleAr,
  errorDescAr,
  setErrorDescAr,
  titl,
  titleAr,
  setTitle,
  setTitleAr,
  description,
  setDescription,
  descriptionAr,
  setDescriptionAr,
}) {
  return (
    <>
      <div className="">
        <h2 className="mb-5 fw-semibold"style={{ color: "grey" }}>{title}</h2>
      </div>
      <div className="view-page d-flex justify-content-between mb-5">
        <div className="w-100 text-center px-5">
          <InputLabel
            style={{
              textAlign: "start",
              marginBottom: 14,
              fontSize: 20,
              color: "grey",
            }}
          >
            English Title
          </InputLabel>
          <TextField
            value={titl}
            label="Add Title"
            variant="outlined"
            fullWidth
            sx={{ backgroundColor: "white" }}
            error={errorTitle}
            // helperText={errorTitle}
            onChange={(e) => {
              if(setTitle){
                setTitle(e.target.value)
              }
              console.log(fullData);
              if (e.target.value === "") {
                setErrorTitle("Enter title");
              } else if (e.target.value.length < 2) {
                setErrorTitle("Title length must be at least 2");
              } else {
                setErrorTitle("");
                setFullData({
                  ...fullData,
                  title: {
                    ...fullData.title,
                    en: e.target.value,
                  },
                });
              }
            }}
          />
          {errorTitle && <div className="text-danger text-start ms-3 my-1" style={{fontSize: "12px"}}>{errorTitle}</div>}
        </div>
        <div className="w-100 text-center px-5">
          <InputLabel
            style={{
              textAlign: "start",
              marginBottom: 14,
              fontSize: 20,
              color: "grey",
            }}
          >
            Arabic Title
          </InputLabel>
          <TextField
            value={titleAr}
            label="Add Title"
            variant="outlined"
            fullWidth
            sx={{ backgroundColor: "white" }}
            error={errorTitleAr}
            // helperText={errorTitleAr}
            onChange={(e) => {
              if(setTitleAr){
                setTitleAr(e.target.value)
              }
              console.log(fullData);
              if (e.target.value === "") {
                setErrorTitleAr("Enter title");
              } else if (e.target.value.length < 2) {
                setErrorTitleAr("Title length must be at least 2");
              } else {
                setErrorTitleAr("");
                setFullData({
                  ...fullData,
                  title: {
                    ...fullData.title,
                    ar: e.target.value,
                  },
                });
              }
            }}
          />
          {errorTitleAr && <div className="text-danger text-start ms-3 my-1" style={{fontSize: "12px"}}>{errorTitleAr}</div>}
        </div>
      </div>
      <div className="view-page d-flex justify-content-between mb-5 gap-5 px-5">
        <div className="w-100 text-center">
          <InputLabel
            style={{
              textAlign: "start",
              marginBottom: 14,
              fontSize: 20,
              color: "grey",
            }}
          >
            English Description
          </InputLabel>
          <textarea
            value={description}
            className="form-control"
            label="Description"
            sx={{ backgroundColor: "white" }}
            style={{ width: "100%", height: 50 }}
            placeholder="Add Description"
            onChange={(e) => {
              if(setDescription){
                setDescription(e.target.value)
              }
              console.log(fullData);
              if (e.target.value === "") setErrorDesc("Enter Description");
              else if (e.target.value.length < 3) {
                setErrorDesc("Description length must be at least 3");
              } else {
                setErrorDesc("");
                setFullData({
                  ...fullData,
                  description: {
                    ...fullData.description,
                    en: e.target.value,
                  },
                });
              }
            }}
          />
          {errorDesc && <div className="text-danger text-start ms-3 my-1" style={{fontSize: "12px"}}>{errorDesc}</div>}
        </div>
        <div className="w-100 text-center">
          <InputLabel
            style={{
              textAlign: "start",
              marginBottom: 14,
              fontSize: 20,
              color: "grey",
            }}
          >
            Arabic Description
          </InputLabel>
          <textarea
            value={descriptionAr}
            className="form-control"
            label="Description"
            sx={{ backgroundColor: "white" }}
            style={{ width: "100%", height: 50 }}
            placeholder="Add Description"
            onChange={(e) => {
              if(setDescriptionAr){
                setDescriptionAr(e.target.value)
              }
              console.log(fullData);
              if (e.target.value === "") setErrorDescAr("Enter Description");
              else if (e.target.value.length < 3) {
                setErrorDescAr("Description length must be at least 3");
              } else {
                setErrorDescAr("");
                setFullData({
                  ...fullData,
                  description: {
                    ...fullData.description,
                    ar: e.target.value,
                  },
                });
              }
            }}
          />
          {errorDescAr && <div className="text-danger text-start ms-3 my-1" style={{fontSize: "12px"}}>{errorDescAr}</div>}
        </div>
      </div>
    </>
  );
}

export default NameDesc;
