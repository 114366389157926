import React, { useState, useEffect } from "react";
import logo from "../assets/New Colors_2.gif";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@mui/icons-material/Visibility";
import FormControl from "@mui/material/FormControl";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import { Link } from "react-router-dom";
import { axiosInstance } from "../config/axiosInstance";
import swal from "sweetalert";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import "../styles/responsive.css";

function DeleteAccount() {
  const [request, setRequest] = useState(false);
  const [theme, setTheme] = useState("");
  const daymode = useSelector((state) => {
    return state.switchMode.mode;
  });
  const cookies = document.cookie;
  const arrayCookies = cookies.split(";");
  let token = "";
  for (let i = 0; i < arrayCookies.length; i++) {
    if (arrayCookies[i].includes("access_token_2ladue=")) {
      token = arrayCookies[i].split("=");
    }
  }
  useEffect(() => {
    const thememode = localStorage.getItem("theme");
    setTheme(thememode);
  }, [daymode]);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const navigate = useNavigate();
  const loginFormik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email("Invalid email format")
        .required("Enter your email"),
      password: yup
        .string()
        .required("Enter your password")
        .min(6, "Password length is less than 6"),
    }),
    onSubmit: (values) => {
      console.log(values);
      setRequest(true);
      axios.get("https://api.2ladue.com/sanctum/csrf-cookie").then((result) => {
        console.log(result);
        axiosInstance
          .delete(`/auth/deleteAccount?source=web&email=${values.email}&password=${values.password}`)
          .then((res) => {
            console.log(res.data);
            localStorage.removeItem("adm2ladue")
            document.cookie = `access_token_2ladue=${token};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
            swal("Account deleted successfully");
            navigate("/");
          })
          .catch((err) => {
            console.log(err);
            swal("Something went wrong");
            setRequest(false);
          });
      });
    },
  });
  return (
    <div
      className={`${theme === "Night" ? "bg-dark" : "bg-light"} py-5`}
      style={{ width: "100%", height: "100vh" }}
    >
      <div
        className="login-screen d-flex justify-content-center rounded-4 p-4"
        // style={{ marginLeft: "150px", marginRight: "150px", backgroundColor: "#fcfcfb" }}
      >
        <div className="delete-account-form text-center d-flex justify-content-center flex-column rounded-3">
          <div className="fs-4 fw-semibold mb-5">Delete Account</div>
          <form
            onSubmit={loginFormik.handleSubmit}
            encType="multipart/form-data"
            className="login-form"
          >
            <div className="mb-4">
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                name="email"
                error={loginFormik?.errors?.email}
                fullWidth
                helperText={loginFormik?.errors?.email}
                onChange={loginFormik.handleChange}
                onBlur={loginFormik.handleBlur}
                value={loginFormik.values.email}
              />
            </div>
            <div className="position-relative mb-2">
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  fullWidth
                  name="password"
                  type={showPassword ? "text" : "password"}
                  error={loginFormik?.errors?.password}
                  helperText={loginFormik?.errors?.password}
                  onChange={loginFormik.handleChange}
                  onBlur={loginFormik.handleBlur}
                  value={loginFormik.values.password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                        <Visibility />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                {loginFormik.errors.password && (
                  <small
                    className="text-danger text-start ms-3 my-1"
                    style={{ fontSize: 10 }}
                  >
                    {loginFormik.errors.password}
                  </small>
                )}
              </FormControl>
            </div>
            <div className="mb-5 d-flex justify-content-end"></div>
            <button
              data-bs-toggle="modal"
              data-bs-target="#confirmation"
              className="btn btn-primary w-100"
              type="button"
              disabled={
                loginFormik.errors.email ||
                loginFormik.errors.password ||
                request
              }
            >
              {request ? (
                <CircularProgress size={14} sx={{ color: "white" }} />
              ) : (
                "Delete"
              )}
            </button>
            <div
              className="modal fade"
              id="confirmation"
              tabindex="-1"
              aria-labelledby="confirmationLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="confirmationLabel">
                      Delete Account
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">Are you sure you want to delete this account?</div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary"
                      type="submit"
                      data-bs-dismiss="modal"
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default DeleteAccount;
