import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import AddIcon from "@mui/icons-material/Add";
import { useSelector, useDispatch } from "react-redux";
import { dayAction, nightAction } from "../redux/actions/DayAndNightAction";
import Button from "@mui/material/Button";

function ServerTable({
  data,
  columns,
  addButton,
  type,
  setType,
  // fixedHeader,
  // noHeader,
  // paginationPerPage,
  // paginationRowsPerPageOptions,
  title,
  // selectableRowSelected,
  // handlePageChange,
  // handlePerRowsChange,
  // paginationTotalRows,
  // noPagination,
  // corporateUUid,
  // fetchReport,
  // hideFilters,
  // setSelectedType,
  // selectedType,
  // options,
  search,
  // billingTable = false,
  filters,
  // headFilters,
  // page,
  // setPage,
  ...rest
}) {
  const [theme, setTheme] = useState("");
  const [show, setShow] = useState(data)
  const daymode = useSelector((state) => {
    return state.switchMode.mode;
  });
  useEffect(() => {
    const thememode = localStorage.getItem("theme");
    setTheme(thememode);
  }, [daymode]);
  useEffect(()=>{
    if(type==="all"){
      setShow(data)
    } else {
      const filtered = data?.filter((item,idx)=>{
        if(title === "Sliders" || title === "Products"){
          return item.type === type
        } else if (title === "Auctions"){
          return item.status === type
        }
      })
      setShow(filtered)
    }
  }, [data])
  const customStyles = {
    headCells: {
      style: {
        color: "grey",
        fontSize: "0.9rem",
        // justifyContent:"center",
        backgroundColor: theme === "Night" ? "#202442" : "white",
      },
    },
    rows: {
      style: {
        maxHeight: "500px",
        justifyContent: "center",
        backgroundColor: theme === "Night" ? "#202442" : "white",
        color: theme === "Night" ? "white" : "black",
      },
    },
    pagination: {
      style: {
        backgroundColor: theme === "Night" ? "#202442" : "white",
        // color: theme === "Night" ? "white" : "black",
      },
    },
    // paginationButtons: {
    //   style: {
    //     color: theme === 'Night' ? "white" : "black"
    //   }
    // }
  };
  function handleClick(e) {
    setType(e.target.innerHTML.toLowerCase())
    let x = document.getElementsByTagName("p");
    for (let i = 1; i < x.length; i++) {
      x[i].removeAttribute("class");
    }
    e.target.setAttribute(
      "class",
      "py-2 px-3 rounded-4 fw-semibold bg-info bg-opacity-25"
    );
    let tar = e.target.innerHTML.toLowerCase()
    console.log(tar);
    const filtered = data?.filter((item,index)=>{
      if(tar === "all"){
        return data
      } else {
        if(title === "Sliders" || title === "Products"){
          return item.type === tar
        } else if (title === "Auctions"){
          return item.status === tar
        } else if (title === "Packages"){
          return item.duration_str.includes(tar)
        } else if(title === "Orders"){
          return item.type === tar
        }
      }
    })
    console.log(filtered);
    setShow(filtered)
  }
  return (
    <>
      {addButton && (
        <div
          className="table-header p-3 rounded-4 mb-3"
          style={{
            boxShadow: "1px 1px 10px 0px lightgrey",
            backgroundColor: theme === "Night" ? "#202442" : "white",
          }}
        >
          <div
            className="d-flex justify-content-start align-items-center"
            style={{
              fontSize: "0.8rem",
              color: theme === "Night" ? "white" : "black",
            }}
          >
            {filters?.map((item, idx) => {
              return (
                <div key={idx} className="px-3">
                  <p
                    style={{ cursor: "pointer", marginBottom: 0 }}
                    onClick={handleClick}
                  >
                    {item}
                  </p>
                </div>
              );
            })}
          </div>
          <div className=" d-flex justify-content-end">
            {/* <button className="btn btn-outline-primary" style={{fontSize: "0.8rem"}}><AddIcon /> {addButton}</button> */}
            {/* <Button variant="contained" style={{ fontSize: "0.8rem" }}>
              <AddIcon /> {"Add Product"}
            </Button> */}
            {addButton}
          </div>
        </div>
      )}
      <div
        className="p-4 rounded-3 "
        style={{
          boxShadow: "1px 1px 10px 0px lightgrey",
          backgroundColor: theme === "Night" ? "#202442" : "white",
        }}
      >
        <div className="d-flex justify-content-between mb-3">
          <div
            className="fs-5 fw-semibold pb-4"
            style={{ color: theme === "Night" ? "white" : "black" }}
          >
            {title}
          </div>
          {search}
        </div>
        <DataTable
          // fixedHeader
          // noHeader={noHeader}
          // fixedHeaderScrollHeight="350px"
          data={show}
          columns={columns}
          // theme={theme}
          // selectableRowSelected={selectableRowSelected}
          // highlightOnHover
          defaultSortField="id"
          defaultSortAsc={false}
          customStyles={customStyles}
          // paginationComponent={}
          // pagination

          pagination={true}
          // paginationServer={!noPagination}
          // paginationPerPage={paginationPerPage}
          // paginationTotalRows={paginationTotalRows}
          // paginationRowsPerPageOptions={paginationRowsPerPageOptions}
          // paginationComponentOptions={{
          //   rowsPerPageText: t("rows_per_page_key"),
          //   rangeSeparatorText: t("of_key"),
          // }}
          // actions
          // onChangePage={handlePageChange}
          // onChangeRowsPerPage={handlePerRowsChange}
          // progressPending={rest.loading}
          // progressComponent={<Spinner className="w-10 my-44" />}
          noDataComponent={<h3 className="py-5 text-center w-100" style={{ color: "grey", height: "100%",
            backgroundColor: theme === "Night" ? "#202442" : "white", margin: 0
          }}>There is no data to show</h3>}
          // {...rest}
        />

        {/* {!noPagination && (
        <>
        <hr className="my-1" />
        
        <div className="my-6 flex items-center justify-between ">
        <div className="flex items-center  gap-2 w-1/2 ">
        <p className="text-xs text__gray">{`${t("showing_key")} ${
          data.length
        } ${t("of_key")} ${paginationTotalRows}  ${t(
          "result_found_key"
          )}`}</p>
          <select
          onChange={(e) => handlePerRowsChange(+e.target.value)}
          className="text-xs border text__gray font-semibold rounded-lg py-2 px-3"
          >
          <option value="10">{t("show_key")} 10</option>
          <option value="25">{t("show_key")} 25</option>
          <option value="50">{t("show_key")} 50</option>
          </select>
          </div>
          
          <Stack spacing={2}>
          <Pagination
          count={Math.ceil(paginationTotalRows / paginationPerPage)}
          page={page}
          onChange={(event, pageNumber) => handlePageChange(pageNumber , search?.props?.searchQuery)}
          showFirstButton
          showLastButton
          color="primary"
          renderItem={(item) => (
            <PaginationItem
            components={{
              last: (props) => (
                <button {...props}>
                <DoubleArrowRight />
                </button>
                ),
                first: (props) => (
                  <button {...props}>
                  <DoubleArrowLeft />
                  </button>
                  ),
                }}
                {...item}
                />
                )}
                />
                </Stack>
                </div>
                </>
              )} */}
      </div>
    </>
  );
}

export default ServerTable;
