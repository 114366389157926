import React, { useEffect, useState } from "react";
import prods from "../dummydata/prods.json";
import { useLocation, useNavigate } from "react-router-dom";
import "react-slideshow-image/dist/styles.css";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { axiosInstance } from "../config/axiosInstance";
import swal from "sweetalert";
import loader from "../assets/001 EN.gif";
import categ from "../assets/category.png";
import axios from "axios";

function Category() {
  const nav = useNavigate();
  const url = useLocation();
  const prodid = url.pathname.split("/");
  const [theme, setTheme] = useState("");
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const daymode = useSelector((state) => {
    return state.switchMode.mode;
  });
  useEffect(() => {
    const thememode = localStorage.getItem("theme");
    setTheme(thememode);
  }, [daymode]);
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios
      .get("https://api.2ladue.com/sanctum/csrf-cookie")
      .then((response) => {
        console.log(response);
        axiosInstance
          .get(`/modules/product-category/${prodid[prodid.length - 1]}`)
          .then((res) => {
            console.log(res.data.data);
            setProduct(res.data.data);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
            swal("Something went wrong");
          });
      })
      .catch((err) => {
        console.log(err);
        swal("Something went wrong");
      });
  }, []);
  const EditStyles = styled("div")(({ theme }) => ({
    cursor: "pointer",
    backgroundColor: "#ffe000",
    "&:hover": {
      transform: "scale(1.2)",
    },
  }));
  const DeleteStyles = styled("div")(({ theme }) => ({
    cursor: "pointer",
    backgroundColor: "#ff485e",
    "&:hover": {
      transform: "scale(1.2)",
    },
  }));
  return (
    <>
      {loading ? (
        <div
          className=" d-flex justify-content-center"
          style={{ height: "100vh", backgroundColor: "#fcfcfb" }}
        >
          <img alt="loading" src={loader} className="loading-img" />
        </div>
      ) : (
        <div
          className={`${theme === "Night" ? "bg-dark" : "bg-light"} py-5`}
          style={{ width: "100%", height: "100vh" }}
        >
          <div className="container">
            <div
              className="p-3 rounded-3 mb-3"
              style={{
                boxShadow: "1px 1px 10px 0px lightgrey",
                backgroundColor: theme === "Night" ? "#202442" : "white",
                minHeight: 250,
              }}
            >
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="fs-3 fw-semibold text-secondary text-capitalize">
                  {product?.title ? product?.title : "-"}
                </div>
                <div className="d-flex justify-content-end gap-3">
                  <EditStyles
                    className="p-2 rounded-2"
                    onClick={() =>
                      nav(`/editslider/${prodid[prodid.length - 1]}`)
                    }
                  >
                    <EditOutlinedIcon style={{ color: "white" }} />
                  </EditStyles>
                  <DeleteStyles className="p-2 rounded-2">
                    <DeleteForeverOutlinedIcon
                      style={{ color: "white" }}
                      onClick={() => {
                        axios.defaults.withCredentials = true;
                        axios.defaults.withXSRFToken = true;
                        axios
                          .get("https://api.2ladue.com/sanctum/csrf-cookie")
                          .then((response) => {
                            console.log(response);
                            axiosInstance
                              .delete(
                                `modules/product-category/${
                                  prodid[prodid.length - 1]
                                }`
                              )
                              .then((res) => {
                                console.log(res.data);
                                swal("Category deleted successfully");
                                nav("/categories");
                              })
                              .catch((err) => {
                                console.log(err);
                                swal("Somthing went wrong");
                              });
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      }}
                    />
                  </DeleteStyles>
                </div>
              </div>
              <div
                className="view-page"
                style={{ fontSize: "0.8rem" }}
              >
                <div className="img-container px-5 d-flex flex-column justify-content-center">
                  <div
                    className=" d-flex justify-content-center"
                    style={{ height: "100px", overflow: "hidden" }}
                  >
                    <img
                      alt="sli"
                      src={product?.icon ? product?.icon : categ}
                    />
                  </div>
                </div>
                <div className="w-100">
                  <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold">
                      Arabic Title
                    </div>
                    <div className=" text-capitalize"
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {product?.title_ar ? product?.title_ar : "-"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold me-5">
                      Description
                    </div>
                    <div
                      className=" text-capitalize"
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {product?.description ? product?.description : "-"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold me-5">
                      Arabic Description
                    </div>
                    <div
                      className=" text-capitalize"
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {product?.description_ar ? product?.description_ar : "-"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold">
                      Products Count
                    </div>
                    <div
                      className=" text-capitalize"
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {product?.products_count}
                    </div>
                  </div>
                  {/* <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold">Created By</div>
                    <div
                      // className="w-50"
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {product?.created_by ? product?.created_by : "-"}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Category;
