import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import { axiosInstance } from "../config/axiosInstance";
import swal from "sweetalert";
import TitleImage from "../components/addAuction/TitleImage";
import PriceQuantity from "../components/addAuction/PriceQuantity";
import { useLocation } from "react-router-dom";
import axios from "axios";
import loader from "../assets/001 EN.gif";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

function EditAuction() {
  const nav = useNavigate();
  const url = useLocation();
  const prodid = url.pathname.split("/");
  const steps = ["Title and Image", "Price, Quantity and Duration"];
  const [theme, setTheme] = useState("");
  const [stage, setStage] = useState(0);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorTitleAr, setErrorTitleAr] = useState("");
  const [errorPrice, setErrorPrice] = useState("");
  const [errorQty, setErrorQty] = useState("");
  const [errorStart, setErrorStart] = useState("");
  const [errorEnd, setErrorEnd] = useState("");
  const [errorProd, setErrorProd] = useState("Enter value");
  const [open, setOpen] = React.useState(false);
  const [openErr, setOpenErr] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState(null);
  const [request, setRequest] = useState(false);
  const [auc, setAuc] = useState(null)
  const [title, setTitle] = useState(null)
  const [titleAr, setTitleAr] = useState(null)
  const [price, setPrice] = useState(null)
  const [strD, setStrD] = useState(null)
  const [endD, setEndD] = useState(null)
  const [strT, setStrT] = useState(null)
  const [endT, setEndT] = useState(null)
  const [fullData, setFullData] = useState({
    // start_at: new Date(),
    // end_at: new Date(),
    quantity: 1,
    price: 1,
  });
  const daymode = useSelector((state) => {
    return state.switchMode.mode;
  });
  useEffect(() => {
    const thememode = localStorage.getItem("theme");
    setTheme(thememode);
  }, [daymode]);
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios.get('https://api.2ladue.com/sanctum/csrf-cookie').then((result)=>{
      console.log(result);
    axiosInstance
      .get(`/modules/auction/${prodid[prodid.length - 1]}`)
      .then((res) => {
        console.log(res.data.data);
        setAuc(res.data.data);
        setTitle(res.data.data?.title_en)
        setTitleAr(res.data.data?.title_ar)
        setPrice(res.data.data?.price)
        let start = res.data.data?.start.split(" ")
        let end = res.data.data?.end.split(" ")
        console.log(end[0]);
        setStrD(start[0])
        setEndD(end[0])
        setStrT(dayjs(res.data.data?.start))
        setEndT(dayjs(res.data.data?.end))
        setFullData({
          ...fullData,
          title: {
            ar: res.data.data?.title_ar,
            en: res.data.data?.title_en,
          },
          // cover: res.data.data?.image,
          price: res.data.data?.price,
          // quantity: res.data.data?.quantity,
          dstart: start[0],
          dend: end[0],
          tstart: `${start[1]}${start[2]}`,
          tend: `${end[1]}${end[2]}`,
        })
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        swal("Something went wrong");
      })
    }).catch((error)=>{
      console.log(error);
    })
  }, []);
  useEffect(() => {
    setLoading(true);
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios
      .get("https://api.2ladue.com/sanctum/csrf-cookie")
      .then((response) => {
        console.log(response);
        axiosInstance
          .get("modules/product")
          .then((res) => {
            console.log(res.data.data);
            setProducts(res.data.data.data);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
            swal("Something went wrong");
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenErr = () => {
    setOpenErr(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseErr = () => {
    setOpenErr(false);
  };
  function handleDisable() {
    if (stage === 0) {
      if (
        (fullData?.title?.ar && fullData?.title?.en) ||
        request
      )
        return false;
      else return true;
    } else if (stage === 1) {
      if (
        (fullData?.price &&
          fullData?.quantity &&
          fullData?.dstart &&
          fullData?.dend &&
          fullData?.tstart &&
          fullData?.tend &&
          fullData?.product_id) ||
        request
      )
        return false;
      else return true;
    }
  }
  const handleAddProduct = () => {
    setRequest(true)
    const form = new FormData();
    for (let dataKey in fullData) {
      if (dataKey === "title") {
        for (let previewKey in fullData[dataKey]) {
          form.append(`title[${previewKey}]`, fullData[dataKey][previewKey]);
        }
      } else {
        form.append(dataKey, fullData[dataKey]);
      }
    }
    form.append("start_at", `${fullData?.dstart} ${fullData?.tstart}`)
    form.append("end_at", `${fullData?.dend} ${fullData?.tend}`)
    form.append("_method", "PATCH");
    console.log(fullData?.tstart, fullData?.tend);
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios
      .get("https://api.2ladue.com/sanctum/csrf-cookie")
      .then((response) => {
        console.log(response);
        axiosInstance
          .post(`/modules/auction/${prodid[prodid.length - 1]}`, form)
          .then((res) => {
            console.log(res);
            handleClose();
            setRequest(false)
            swal("Auction edited successfully");
            nav("/auctions");
          })
          .catch((err) => {
            console.log(err);
            swal("Something went wrong");
            setRequest(false)
          });
      })
      .catch((err) => {
        console.log(err);
        setRequest(false)
      });
  };
  return (
    <>
      {loading ? (
        <div
          className=" d-flex justify-content-center"
          style={{ height: "100vh", backgroundColor: "#fcfcfb" }}
        >
          <img alt="loading" src={loader} className="loading-img" />
        </div>
      ) : (
        <div
          style={{ width: "100%", minHeight: "100vh" }}
          className={`${theme === "Night" ? "bg-dark" : "bg-light"} py-5`}
        >
          <div className=" container px-5">
            <Stepper activeStep={stage} alternativeLabel sx={{ mb: 10 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>
                    <span
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {label}
                    </span>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {stage === 0 && (
              <TitleImage
                setFullData={setFullData}
                stage={stage}
                setStage={setStage}
                title={"Title and Image"}
                fullData={fullData}
                setErrorTitle={setErrorTitle}
                errorTitle={errorTitle}
                setErrorTitleAr={setErrorTitleAr}
                errorTitleAr={errorTitleAr}
                auc={auc}
                titl={title}
                titleAr={titleAr}
                setTitle={setTitle}
                setTitleAr={setTitleAr}
              />
            )}
            {stage === 1 && (
              <PriceQuantity
                setFullData={setFullData}
                stage={stage}
                setStage={setStage}
                title={"Price, Quantity and Duration"}
                fullData={fullData}
                setErrorPrice={setErrorPrice}
                errorPrice={errorPrice}
                errorQty={errorQty}
                setErrorQty={setErrorQty}
                errorStart={errorStart}
                setErrorStart={setErrorStart}
                errorEnd={errorEnd}
                setErrorEnd={setErrorEnd}
                setErrorProd={setErrorProd}
                errorProd={errorProd}
                products={products}
                price={price}
                setPrice={setPrice}
                strD={strD}
                strT={strT}
                endD={endD}
                endT={endT}
                setEndD={setEndD}
                setEndT={setEndT}
                setStrD={setStrD}
                setStrT={setStrT}
              />
            )}
            <div className=" d-flex justify-content-between">
              <Button
                disabled={(stage === 0)}
                variant="contained"
                sx={{ width: 120 }}
                onClick={() => {
                  if (stage > 0) {
                    setStage(stage - 1);
                  } else {
                    setStage(0);
                  }
                }}
              >
                <div className=" d-flex justify-content-between w-100 align-items-center">
                  <ArrowBackIosNewIcon style={{ fontSize: 15 }} />
                  <span>Back</span>
                </div>
              </Button>
              <Button
                disabled={handleDisable()}
                variant="contained"
                sx={{ width: 120 }}
                onClick={() => {
                  if (stage < 1) {
                    setStage(stage + 1);
                  } else {
                    if (
                      errorEnd === "" &&
                      errorPrice === "" &&
                      errorQty === "" &&
                      errorTitle === "" &&
                      errorStart === "" &&
                      errorProd === "" &&
                      errorTitleAr === ""
                    ) {
                      handleClickOpen();
                    } else {
                      handleClickOpenErr();
                    }
                  }
                }}
              >
                <div className=" d-flex justify-content-between w-100 align-items-center">
                  <span>{stage === 1 ? "Confirm" : "Next"}</span>
                  <ArrowForwardIosIcon style={{ fontSize: 15 }} />
                </div>
              </Button>
            </div>
            <Dialog
              open={open}
              // TransitionComponent={Transition}
              keepMounted
              fullWidth
              maxWidth="sm"
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>
                <p>{"Edit Auction"}</p>
                <Divider sx={{ borderColor: "black" }} />
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Are you sure you want to edit auction with previous data?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button variant="contained" onClick={handleAddProduct} disabled={request}>
                {request ? (
                    <CircularProgress size={14} sx={{ color: "white" }} />
                  ) : (
                    "Edit"
                  )}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openErr}
              // TransitionComponent={Transition}
              keepMounted
              fullWidth
              maxWidth="sm"
              onClose={handleCloseErr}
              aria-describedby="alert-dialog-slide-description1"
            >
              <DialogTitle>
                <p>{"Missing Data"}</p>
                <Divider sx={{ borderColor: "black" }} />
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description1">
                  You have left empty fields
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseErr}>Back</Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      )}
    </>
  );
}

export default EditAuction;
