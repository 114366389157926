import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";

function AdditionalEdit({
  title,
  fullData,
  setFullData,
  setErrorProps,
  props,
  setProps,
}) {
  const [fields, setFields] = useState([]);
  const [key, setKey] = useState(null);
  const [val, setVal] = useState(null);
  useEffect(() => {
    if (props) {
      console.log(props);
      setFields(props);
      let arrK=[]
      let arrV=[]
      props.forEach(element => {
        arrK.push(element.key)
        arrV.push(element.value)
        setKey(arrK)
        setVal(arrV)
      });
    }
    if (fields.length !== 0) setErrorProps("");
    else setErrorProps("Enter property");
  }, []);
  useEffect(() => {
    if (fields.length !== 0) setErrorProps("");
    else setErrorProps("Enter property");
  }, [fields]);
  return (
    <>
      <div className=" d-flex justify-content-between align-items-center mb-5">
        <h2 className="fw-semibold" style={{ color: "grey" }}>
          {title}
        </h2>
        <IconButton
          sx={{ bgcolor: "#2e8bf2" }}
          onClick={() => setFields([...fields, 1])}
        >
          <AddIcon sx={{ color: "lightgrey", fontSize: 30 }} />
        </IconButton>
      </div>
      <div className=" d-flex justify-content-between flex-column mb-5">
        {fields?.map((field, index) => {
          return (
            <div
              key={index}
              className="view-page d-flex justify-content-between gap-5 mb-5"
            >
              <div className="w-100 text-center px-5">
                <InputLabel
                  style={{
                    textAlign: "start",
                    marginBottom: 14,
                    fontSize: 20,
                    color: "grey",
                  }}
                >
                  Property Key
                </InputLabel>
                <TextField
                  label="Add Property Key"
                  variant="outlined"
                  fullWidth
                  sx={{ backgroundColor: "white" }}
                  value={key[index]}
                  //   error={errorTitle}
                  // helperText={errorTitle}
                  onChange={(e) => {
                    if(props){
                      let keys = [...key]
                      keys[index] = e.target.value
                      setKey(keys)
                    }
                    if (e.target.value === "") {
                      //   setErrorTitle("Enter title");
                    } else if (e.target.value.length < 2) {
                      // setErrorTitle("Title length must be at least 2");
                    } else {
                      let ke = fullData;
                      ke.properties[index] = {
                        ...ke.properties[index],
                        key: e.target.value,
                      };
                      setFullData(ke);
                      // let keys;
                      // if(fullData?.properties?.key){
                      //     keys = [...fullData?.properties?.key]
                      // }else{
                      //     keys = []
                      // }
                      // keys[index] = e.target.value
                      // setFullData({
                      //     ...fullData,
                      //     properties: {
                      //         ...fullData?.properties,
                      //         key: keys
                      //     },
                      // });
                    }
                  }}
                />
                {/* {errorTitle && (
                  <div
                    className="text-danger text-start ms-3 my-1"
                    style={{ fontSize: "12px" }}
                  >
                    {errorTitle}
                  </div>
                )} */}
              </div>
              <div className="w-100 text-center px-5">
                <InputLabel
                  style={{
                    textAlign: "start",
                    marginBottom: 14,
                    fontSize: 20,
                    color: "grey",
                  }}
                >
                  Property Value
                </InputLabel>
                <TextField
                  label="Add Property Value"
                  variant="outlined"
                  fullWidth
                  sx={{ backgroundColor: "white" }}
                  value={val[index]}
                  //   error={errorTitle}
                  // helperText={errorTitle}
                  onChange={(e) => {
                    if(props){
                      let vals = [...val]
                      vals[index] = e.target.value
                      setVal(vals)
                    }
                    if (e.target.value === "") {
                      //   setErrorTitle("Enter title");
                    } else if (e.target.value.length < 2) {
                      // setErrorTitle("Title length must be at least 2");
                    } else {
                      let val = fullData;
                      val.properties[index] = {
                        ...val.properties[index],
                        value: e.target.value,
                      };
                      setFullData(val);
                      // let vals = [...fullData?.properties?.value]
                      // vals[index] = e.target.value
                      // setFullData({
                      //     ...fullData,
                      //     properties: {
                      //         ...fullData?.properties,
                      //         value: vals
                      //     },
                      // });
                    }
                    console.log(fullData);
                  }}
                />
                {/* {errorTitle && (
                  <div
                    className="text-danger text-start ms-3 my-1"
                    style={{ fontSize: "12px" }}
                  >
                    {errorTitle}
                  </div>
                )} */}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default AdditionalEdit;
