import React, { useEffect, useState } from "react";
import { axiosInstance } from "../config/axiosInstance";
import swal from "sweetalert";
import axios from "axios";
import loader from "../assets/001 EN.gif";
import Grid from '@mui/material/Grid';
import ServerTableDash from "../components/ServerTableDash";
import { prodsColDash, categColDash, sliderColDash, packageColDash, auctionsColDash, customersColDash } from "../components/columns";
import { useSelector, useDispatch } from "react-redux";

function DashboardHome() {
    const [theme, setTheme] = useState("");
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState(null)
    const [auctions, setAuctions] = useState(null)
    const [categories, setCategories] = useState(null)
    const [packages, setPackages] = useState(null)
    const [sliders, setSliders] = useState(null)
    const [customers, setCustomers] = useState(null)
    const daymode = useSelector((state) => {
        return state.switchMode.mode;
      });
      useEffect(() => {
        const thememode = localStorage.getItem("theme");
        setTheme(thememode);
      }, [daymode]);
    useEffect(() => {
        async function getColsData() {
            setLoading(true)
            try {
                axios.defaults.withCredentials = true;
                axios.defaults.withXSRFToken = true;
                const csrfToken = await axios.get("https://api.2ladue.com/sanctum/csrf-cookie")
                if (csrfToken.status === 204) {
                    const prods = await axiosInstance.get("modules/product?items=5&page=1")
                    console.log(prods);
                    setProducts(prods.data.data?.data)
                    const aucs = await axiosInstance.get("modules/auction?items=5&page=1")
                    console.log(aucs);
                    setAuctions(aucs.data.data?.data)
                    const cates = await axiosInstance.get("modules/product-category?items=5&page=1")
                    console.log(cates);
                    setCategories(cates.data.data?.data)
                    const slids = await axiosInstance.get("modules/home-slider?items=5&page=1")
                    console.log(slids);
                    setSliders(slids.data.data?.data)
                    const packs = await axiosInstance.get("modules/package?items=5&page=1")
                    console.log(packs);
                    setPackages(packs.data.data?.data)
                    const custs = await axiosInstance.get("modules/client?items=5&page=1")
                    console.log(custs);
                    setCustomers(custs.data.data?.data)
                    if (!prods.status.toString().includes("20") || !aucs.status.toString().includes("20") || !cates.status.toString().includes("20") || !slids.status.toString().includes("20") || !packs.status.toString().includes("20") || !custs.status.toString().includes("20")) {
                        swal("Something went wrong");
                    }
                    setLoading(false)
                } else {
                    swal("Something went wrong");
                    setLoading(false)
                }
            } catch (error) {
                console.log(error);
                // swal("Something went wrong");
                setLoading(false)
            }
        }
        getColsData()
    }, [])
    return (
        <>
            {loading ? (
                <div
                    className=" d-flex justify-content-center"
                    style={{ height: "100vh", backgroundColor: "#fcfcfb" }}
                >
                    <img alt="loading" src={loader} className="loading-img" />
                </div>
            ) : (
                <div
                    style={{ width: "100%", minHeight: "100vh" }}
                    className={`${theme === "Night" ? "bg-dark" : "bg-light"} py-5`}
                >
                    <div className="container">
                        <Grid container spacing={4}>
                            <Grid item sm={6} xs={12}>
                                <ServerTableDash
                                    title="Products"
                                    type={"all"}
                                    data={products ? products : []}
                                    columns={prodsColDash()}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <ServerTableDash
                                    title="Categories"
                                    type={"all"}
                                    data={categories ? categories : []}
                                    columns={categColDash()}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <ServerTableDash
                                    title="Auctions"
                                    type={"all"}
                                    data={auctions ? auctions : []}
                                    columns={auctionsColDash()}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <ServerTableDash
                                    title="Packages"
                                    type={"all"}
                                    data={packages ? packages : []}
                                    columns={packageColDash()}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <ServerTableDash
                                    title="Sliders"
                                    type={"all"}
                                    data={sliders ? sliders : []}
                                    columns={sliderColDash()}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <ServerTableDash
                                    title="Customers"
                                    type={"all"}
                                    data={customers ? customers : []}
                                    columns={customersColDash()}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            )}
        </>
    )
}

export default DashboardHome;