import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: "https://api.2ladue.com/api/v1/admin",
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
    "Api-Key":
      "eyJpdiI6ImprWVlocFM5R3liUTRqZ01HbFRrZlE9PSIsInZhbHVlIjoiL2krVFVSUzROWmtjVUpoaXdEYTZadmIvT3hwN1Y1MHNkUGlTazBoZjZocTN5cDR6dzBOSHFVeEZ1d2trNXJ6TiIsIm1hYyI6ImQyZDFjNjFmZjdkNzlhZTYyNzg1N2ZlMjg1ZGM2YWY3Njc0Zjk0MmI4OGZkYjcwZWQ3MzNiY2Y5ODI2NTZkZDIiLCJ0YWciOiIifQ==",
    "Api-Locale": "en",
    "Api-Version": "v1",
  },
  xsrfHeaderName: "X-XSRF-TOKEN",
  withCredentials: true,
  withXSRFToken: true,
  // xsrfCookieName: ""
});

axiosInstance.interceptors.request.use(
  (req) => {
    const cookies = document.cookie;
    const arrayCookies = cookies.split(";");
    let token = "";
    for (let i = 0; i < arrayCookies.length; i++) {
      if (arrayCookies[i].includes("access_token_2ladue=")) {
        token = arrayCookies[i].split("=");
      }
    }
    if (token) {
      req.headers.Authorization = `Bearer ${token[1]}`;
    }
    return req;
  },
  (err) => Promise.reject(err)
);

axiosInstance.interceptors.response.use(
  (res) => {
    if (res?.data?.data?.access_token) {
      const timeNow = new Date();
      const expireTime = new Date();
      expireTime.setTime(timeNow.getTime() + 120 * 60 * 1000);
      document.cookie = `access_token_2ladue=${
        res?.data?.data?.access_token
      }; expires=${expireTime.toUTCString()}`;
    }
    return res;
  },
  (err) => Promise.reject(err)
);
