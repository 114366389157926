import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { FileUploader } from "react-drag-drop-files";
import AddIcon from "@mui/icons-material/Add";

function AddImages({ setFullData, setStage, stage, title, fullData, cover, coverr, setCover, setGallery, gallery }) {
  const [imagesGrid, setImagesGrid] = useState([]);
  const [imagee, setImagee] = useState(null);
  useEffect(()=>{
    if(gallery) setImagesGrid(gallery)
    if(coverr) setImagee(coverr)
  }, [])
  const fileTypes = ["JPG", "PNG", "JPEG"];
  const handleChange = (files) => {
    setFullData({
      ...fullData,
      gallery: [...fullData?.gallery, ...files],
    });
    let images = [];
    if (files !== undefined) {
      for (let i = 0; i < files.length; i++) {
        const fileReader = new FileReader();
        let image = document.createElement("img");
        image.src = URL.createObjectURL(files[i]);
        images.push({image: image.src});
        fileReader.readAsDataURL(files[i]);
      }
      setImagesGrid([...imagesGrid, ...images]);
    }
  };

  const handleChangeCover = (files) => {
    console.log(files);
    setFullData({
      ...fullData,
      cover: files,
    });
    if (files !== undefined) {
      const fileReader = new FileReader();
      let image = document.createElement("img");
      image.src = URL.createObjectURL(files);
      setImagee(image.src);
      fileReader.readAsDataURL(files);
    }
  };

  return (
    <>
      <div className="mb-5">
        <Grid container spacing={1} sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <Grid sm={6} xs={12}>
            <h2 className="mb-5 fw-semibold w-100" style={{ color: "grey" }}>
              {cover}
            </h2>
            <input
              className="form-control"
              type="file"
              accept="image/*"
              name="file"
              onChange={(e) => handleChangeCover(e.target.files[0])}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            {imagee && (
              <div
                className=" d-flex justify-content-center mt-5"
                style={{ height: "150px", overflow: "hidden" }}
              >
                <img
                  alt={"idd"}
                  src={imagee}
                  width={"auto"}
                  height={"150px"}
                  className=" border border-1 rounded-2"
                />
              </div>
            )}
          </Grid>
        </Grid>
      </div>
      <div className="mb-5">
        <h2 className="w-100 mb-5 fw-semibold w-100" style={{ color: "grey" }}>
          {title}
        </h2>
        <div className="input-group mb-3">
          <input
            className="form-control"
            type="file"
            accept="image/*"
            name="file"
            multiple
            onChange={(e) => handleChange(e.target.files)}
          />
        </div>
      </div>
      <Grid container spacing={4} sx={{ mb: 20 }}>
        {imagesGrid.length !== 0 &&
          imagesGrid.map((itt, idd) => {
            return (
              <Grid
                key={idd}
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                sx={{ textAlign: "center" }}
              >
                <div style={{ height: "150px", overflow: "hidden" }}>
                  <img
                    alt={idd}
                    src={itt.image}
                    width={"auto"}
                    height={"150px"}
                    className=" border border-1 rounded-2"
                  />
                </div>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
}

export default AddImages;
