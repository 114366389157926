export const nightAction = (data) => {
    return {
        type : data,
        payload: data
    }
}

// export const selectTab = (data) => {
//     return {
//         type: data,
//         payload: data
//     }
// }