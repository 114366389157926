import React, { useState, useEffect } from "react";
import { axiosInstance } from "../config/axiosInstance";
import swal from "sweetalert";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import loader from "../assets/001 EN.gif";
import { useSelector } from "react-redux";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { styled } from "@mui/material/styles";
import categ from "../assets/setting.png";

function Setting() {
  const nav = useNavigate();
  const url = useLocation();
  const prodid = url.pathname.split("/");
  const [loading, setLoading] = useState(true);
  const [setting, setSetting] = useState(null);
  const [theme, setTheme] = useState("");
  const daymode = useSelector((state) => {
    return state.switchMode.mode;
  });
  useEffect(() => {
    const thememode = localStorage.getItem("theme");
    setTheme(thememode);
  }, [daymode]);
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios
      .get("https://api.2ladue.com/sanctum/csrf-cookie")
      .then((response) => {
        console.log(response);
        axiosInstance
          .get(`modules/setting/${prodid[prodid.length - 1]}`)
          .then((res) => {
            console.log(res.data.data);
            setSetting(res.data.data);
            setLoading(false);
            // setReload(!reload);
          })
          .catch((err) => {
            console.log(err);
            swal("Somthing went wrong");
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);
  const EditStyles = styled("div")(({ theme }) => ({
    cursor: "pointer",
    backgroundColor: "#ffe000",
    "&:hover": {
      transform: "scale(1.2)",
    },
  }));
  return (
    <>
      {loading ? (
        <div
          className=" d-flex justify-content-center"
          style={{ height: "100vh", backgroundColor: "#fcfcfb" }}
        >
          <img alt="loading" src={loader} className="loading-img" />
        </div>
      ) : (
        <div
          className={`${theme === "Night" ? "bg-dark" : "bg-light"} py-5`}
          style={{ width: "100%", height: "100vh" }}
        >
          <div className="container">
            <div
              className="p-3 rounded-3 mb-3"
              style={{
                boxShadow: "1px 1px 10px 0px lightgrey",
                backgroundColor: theme === "Night" ? "#202442" : "white",
                minHeight: 250,
              }}
            >
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="fs-3 fw-semibold text-secondary text-capitalize">
                  {setting?.title ? setting?.title : "-"}
                </div>
                <div className="d-flex justify-content-end gap-3">
                  <EditStyles
                    className="p-2 rounded-2"
                    onClick={() =>
                      nav(`/editsetting/${prodid[prodid.length - 1]}`)
                    }
                  >
                    <EditOutlinedIcon style={{ color: "white" }} />
                  </EditStyles>
                </div>
              </div>
              <div
                className="d-flex justify-content-between gap-5"
                style={{ fontSize: "0.8rem" }}
              >
                <div className="w-50 px-5 d-flex flex-column justify-content-center">
                  <div
                    className=" d-flex justify-content-center"
                    style={{ height: "100px", overflow: "hidden" }}
                  >
                    <img
                      alt="sli"
                      src={setting?.image ? setting?.image : categ}
                    />
                  </div>
                </div>
                <div className="w-100">
                  <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold">Type</div>
                    <div
                      className=" text-capitalize"
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {setting?.type}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold me-5">
                      Content
                    </div>
                    <div
                    //   className=" text-capitalize"
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {setting?.content ? setting?.content : "-"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Setting;
