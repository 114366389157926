import React, { useEffect } from "react";
import { useLocation, Navigate, Outlet } from "react-router";
import swal from "sweetalert";

const ReqAuth = () => {
  const cookies = document.cookie;
  const arrayCookies = cookies.split(";");
  let token = "";
  for (let i = 0; i < arrayCookies.length; i++) {
    if (arrayCookies[i].includes("access_token_2ladue=")) {
      token = arrayCookies[i].split("=")[1];
    }
  }
  const location = useLocation();

  useEffect(() => {
    if (!token) swal("Please Sign in or Sign Up to continue");
  });

  return token ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default ReqAuth;