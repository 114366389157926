import React, { useEffect, useState } from "react";
import customers from "../dummydata/customers.json";
import { useLocation, useNavigate } from "react-router-dom";
import "react-slideshow-image/dist/styles.css";
import prods from "../dummydata/subs.json";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import Card from "../components/Card";
import loader from "../assets/001 EN.gif";
import { subsCol } from "../components/columns";
import ServerTable from "../components/ServerTable";
import { axiosInstance } from "../config/axiosInstance";
import swal from "sweetalert";
import axios from "axios";

function Customer() {
  const url = useLocation();
  const userid = url.pathname.split("/");
  const nav = useNavigate();
  const columns = subsCol(productClick);
  function productClick(id) {
    console.log(id);
    nav(`/product/${id}`);
  }
  const [selected, setSelected] = useState(null);
  const [val, setVal] = useState("");
  const [theme, setTheme] = useState("");
  const [orders, setOrders] = useState([])
  const [type, setType] = useState("all");
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const daymode = useSelector((state) => {
    return state.switchMode.mode;
  });
  useEffect(() => {
    const thememode = localStorage.getItem("theme");
    setTheme(thememode);
  }, [daymode]);
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios
      .get("https://api.2ladue.com/sanctum/csrf-cookie")
      .then((response) => {
        console.log(response);
        axiosInstance
          .get(`/modules/client/${userid[userid.length - 1]}`, {
            xsrfHeaderName: "X-XSRF-TOKEN", // change the name of the header to "X-XSRF-TOKEN" and it should works
            withCredentials: true,
          })
          .then((res) => {
            console.log(res.data.data);
            setUser(res.data.data);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
            swal("Something went wrong");
          });
        axiosInstance
          .get(`/modules/client/${userid[userid.length - 1]}/orders?type=all`, {
            xsrfHeaderName: "X-XSRF-TOKEN", // change the name of the header to "X-XSRF-TOKEN" and it should works
            withCredentials: true,
          })
          .then((res) => {
            console.log(res.data.data);
            setOrders(res.data.data);
            setSelected(res.data.data);
          })
          .catch((err) => {
            console.log(err);
            swal("Something went wrong");
          });
      })
      .catch((err) => {
        console.log(err);
      });
    // console.log(customers.users);
    // console.log(userid[userid.length - 1]);
    // const myUser = customers.users.filter((item, idx) => {
    //   if (item.id === Number(userid[userid.length - 1])) return item;
    // });
    // console.log(myUser);
    // setUser(myUser[0]);
  }, []);
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: 10,
    backgroundColor: "#eaeaea",
    "&:hover": {
      // backgroundColor: "#ccc345",
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    height: "100%",
    "& .MuiInputBase-input": {
      // padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "30ch",
        },
      },
    },
  }));
  return (
    <>
      {loading ? (
        <div
          className=" d-flex justify-content-center"
          style={{ height: "100vh", backgroundColor: "#fcfcfb" }}
        >
          <img alt="loading" src={loader} className="loading-img" />
        </div>
      ) : (
        <div
          className={`${theme === "Night" ? "bg-dark" : "bg-light"} py-5`}
          style={{ width: "100%", minHeight: "100vh" }}
        >
          <div className="container">
            <div className="" style={{ marginBottom: "400px" }}>
              <Card
                image={user?.image}
                data={{
                  name: `${user?.name}`,
                  email: user?.email,
                  phone: user?.mobile,
                  address: user?.address[0] ? user?.address[0] : "-",
                }}
              />
            </div>
            <div className="cust-table">
              <ServerTable
                title="Orders"
                type={type}
                setType={setType}
                data={selected? selected : []}
                columns={columns}
                search={
                  <div className="search-box">
                    <button className="btn-search">
                      <SearchIcon />
                    </button>
                    <input
                      type="text"
                      className="input-search"
                      placeholder="Search"
                      value={val}
                      onChange={(e) => {
                        setVal(e.target.value);
                        if (e.target.value === "") {
                          setSelected(orders);
                        } else {
                          const filtered = orders?.filter(
                            (item, index) => {
                              return item.related.title
                                .toLowerCase()
                                .includes(e.target.value);
                            }
                          );
                          setSelected(filtered);
                        }
                      }}
                    />
                  </div>
                }
                filters={["All", "Direct", "Package"]}
                  addButton={" "}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Customer;
